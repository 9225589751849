import React, { useContext, useRef } from "react";
import ERC20ABI from "../../abi/ERC20";
import { useState, useEffect } from "react";
import ActionButton from "../../components/ActionButton/ActionButton";
import "./CreatePool.css";
import ReCAPTCHA from "react-google-recaptcha";
import LendingPoolABI from "../../abi/LendingPool.js";
import { ethers } from "@usedapp/core/node_modules/ethers";
import {
  defaultChain,
  fetchLocalTokenData,
  getPoolFactoryContract,
  getTransactionUrl,
  supportedNetworks,
  tabletBreakpoint,
  tokenData,
  getGasLimit,
  networks,
  contractAddresses,
  handleMulticallAddress,
  readableABIs,
  strategies,
  encodeFeeRates,
  getCurrentPage,
  useDevFunctions,
  hasOracle
} from "../../utils/Utils";
import AlertBox from "../../components/AlertBox/AlertBox";
import { useSnackbar } from "notistack";
import { APP_DATA_CONTEXT, METHOD_TYPE, STRATEGY, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { Link, useNavigate } from "react-router-dom";
// import MintRatioWarning from "../../components/MintRatioWarning/MintRatioWarning";
import { WalletDataContext } from "../../context/WalletDataContext";
import { AppDataContext } from "../../context/AppDataContext";
import { useScreenSize } from "../../utils/useScreenSize";
import { BigNumber } from "ethers";
import { Contract as MulticallContract } from "ethers-multicall";
import { Slider } from "@material-ui/core";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import { addDays } from "date-fns";
import GraphTabs from "../../components/GraphTabs/GraphTabs";
import { ChevronRight } from "react-feather";
import WrongNetwork from "../../components/WrongNetwork/WrongNetwork";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import PoolRequestsContainer from "../../components/PoolRequestsContainer/PoolRequestsContainer";
import Pages from "./Pages/Pages";

const CreatePool = () => {

  const { provider, chainId, account, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending, tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  const baseURI = "https://vendor.finance/my-pools/lent/";

  const pages = ["1", "2", "3"];
  const [selectedMintRatio, setSelectedMintRatio] = useState<string | number>(0);
  const [selectedMintRatioButton, setSelectedMintRatioButton] = useState<string | number>("Custom");
  const [selectedExpiry, setSelectedExpiry] = useState(addDays(new Date(), 10));
  const [selectedAPR, setSelectedAPR] = useState<number[]>([17,0]);
  const [selectedAuctionDates, setSelectedAuctionDates] = useState<Date[]>([new Date(), addDays(new Date(), 10)]);
  const [showNewPool, setShowNewPool] = useState(false);
  const [deployedPoolAddress, setDeployedPoolAddress] = useState("");
  const [privateBorrowers, setPrivateBorrowers] = useState<string[]>([]);
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [lendAllowance, setLendAllowance] = useState<BigNumber>(BigNumber.from("0"));
  const [lendBalance, setLendBalance] = useState<BigNumber>(BigNumber.from("0"));
  const [initialDeposit, setInitialDeposit] = useState<number>(0);
  const [selectedStrategy, setSelectedStrategy] = useState<STRATEGY>(strategies["No Strategy"]);
  const [rateType, setRateType] = useState<"Fixed" | "Decay" | "Auction">("Decay");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [stepButtonState, setStepButtonState] = useState<string>("");
  const [poolRequests, setPoolRequests] = useState<any[]>([]);
  const [protocolFee, setProtocolFee] = useState<number>(0);
  const [latestPoolRequest, setLatestPoolRequest] = useState<any>();
  const [autoPause, setAutoPause] = useState<boolean>(false);
  const [selectedCollateral, setSelectedCollateral] = useState<any>({
    // @ts-ignore
    address: tokenData.WETH.address[defaultChain],
    symbol: "WETH",
    hasOracle: true,
    displayDecimals: 4,
    decimals: 0,
    price: 0,
  });
  //eslint-disable-next-line
  const [selectedLend, setSelectedLend] = useState({
    // @ts-ignore
    address: tokenData["USDC.e"].address[defaultChain],
    symbol: "USDC.e",
    hasOracle: true,
    displayDecimals: 4,
    decimals: 6,
    price: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { screenWidth } = useScreenSize();
  const navigate = useNavigate();
  const recaptchaRef = useRef<any>();
  const currentChainRef = useRef<number>();

  // get the token price on load
  useEffect(() => {
    getLendAllowance();
    if (chainId && chainId !== currentChainRef.current) {
      checkChain();
    }
    currentChainRef.current = chainId;
    // eslint-disable-next-line
  }, [chainId, multicallProvider]);

  // reset page back to 0 when user navigates to or away
  useEffect(() => {
    setCurrentPage(0);
  }, [navigate]);

  useEffect(() => {
    getQueryParams();
  // eslint-disable-next-line
  }, [tokenPrices]);

  useEffect(() => {
    if (!selectedLend || selectedLend.address === "") return;
    getLendAllowance();

    // auto-select auto pause if both lend and collateral assets have oracles
    if ((selectedLend.symbol === "USDC" || selectedCollateral.symbol === "USDC") && chainId === 42161)
      setAutoPause(false);
    else if (hasOracle(selectedLend.address) && hasOracle(selectedCollateral.address)) 
      setAutoPause(true);
    else 
      setAutoPause(false);

  // eslint-disable-next-line
  }, [selectedLend, selectedCollateral]);

  const checkChain = () => {
    let lendTokenData, colTokenData;

    if (chainId === networks.ArbitrumGoerli.chainId) {
      lendTokenData = tokenData.USDC;
      colTokenData = tokenData.WBTC;
    } else if (chainId === networks.Arbitrum.chainId) {
      lendTokenData = tokenData["USDC.e"];
      colTokenData = tokenData.WETH;
    } else if (chainId === networks.Base.chainId) {
      lendTokenData = tokenData.USDBC;
      colTokenData = tokenData.WETH;
    } else if (chainId === networks.MantleTestnet.chainId) {
      lendTokenData = tokenData.MUSDC;
      colTokenData = tokenData.WMNT;
    } else {
      lendTokenData = tokenData.USDC;
      colTokenData = tokenData.WETH;
    }

    setSelectedCollateral({
      // @ts-ignore
      address: colTokenData.address[chainId],
      symbol: colTokenData.symbol,
      // @ts-ignore
      hasOracle: hasOracle(colTokenData.address[chainId]),
      displayDecimals: colTokenData.displayDecimals,
      decimals: colTokenData.tokenDecimals,
      price: 0
    })
    setSelectedLend({
      // @ts-ignore
      address: lendTokenData.address[chainId],
      symbol: lendTokenData.symbol,
      // @ts-ignore
      hasOracle: hasOracle(lendTokenData.address[chainId]),
      displayDecimals: lendTokenData.displayDecimals,
      decimals: lendTokenData.tokenDecimals,
      price: 0
    });
  }

  // check the allowance for the currently selected lend token
  const getLendAllowance = async () => {
    if (!provider || !chainId || !multicallProvider) return;
    // setup the correct multicall address
    handleMulticallAddress(chainId, multicallProvider);
    // get reference to contract
    const tokenContract = new MulticallContract(
      ethers.utils.getAddress(selectedLend.address),
      readableABIs.erc20
    );
    // execute contract calls 
    const response = await multicallProvider.all([
      // @ts-ignore
      tokenContract.allowance(account, contractAddresses.POOL_FACTORY.address[chainId]),
      tokenContract.balanceOf(account)
    ]);
    // extract multicall responses
    const [ allowance, balance ] = response;
    // update state
    setLendAllowance(allowance);
    setLendBalance(balance);
  }

  const getQueryParams = () => {

    // extract parameters from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const queryLendToken = urlParams.get("lendToken");
    const queryColToken = urlParams.get("colToken");
    const queryLendRatio = urlParams.get("lendRatio");
    const queryStartRate = urlParams.get("startRate");
    const queryFeeType = urlParams.get("feeType");
    const queryStrategy = urlParams.get("strategy");

    // update pool paramters 
    if (queryLendToken) {
      const lendTokenData = fetchLocalTokenData(queryLendToken, chainId);
      setSelectedLend({
        address: lendTokenData.address[chainId],
        symbol: lendTokenData.symbol,
        hasOracle: lendTokenData.hasOracle,
        displayDecimals: lendTokenData.displayDecimals,
        decimals: lendTokenData.tokenDecimals,
        price: tokenPrices[lendTokenData.address],
      });
    }
    if (queryColToken) {
      const colTokenData = fetchLocalTokenData(queryColToken, chainId);
      setSelectedCollateral({
        address: colTokenData.address[chainId],
        symbol: colTokenData.symbol,
        hasOracle: colTokenData.hasOracle,
        displayDecimals: colTokenData.displayDecimals,
        decimals: colTokenData.tokenDecimals,
        price: tokenPrices[colTokenData.address],
      })
    }
    if (queryLendRatio) {
      const ratio = ethers.utils.formatUnits(queryLendRatio, 18);
      setSelectedMintRatio(Number(ratio));
    }
    if (queryStartRate) {
      const startRate = Number(queryStartRate) / 10000;
      if (queryFeeType && queryFeeType === "1") {
        // for fixed rate, [startRate, startRate]
        setSelectedAPR([startRate, startRate]);
      } else if (queryFeeType && queryFeeType === "2") {
        // for decaying rate, [startRate, 0]
        setSelectedAPR([startRate, 0]);
      }
    }
    if (queryStrategy) {
      // find the strategy based on the query
      const foundStrategy = Object.entries(strategies).find((strategy) => {
        const strategyName = strategy[0];
        const strategyData = strategy[1];
        if (strategyName === "No Strategy") return false;
        if (queryLendToken) {
          // @ts-ignore
          const strategySupport = strategyData.supportedTokens[ethers.utils.getAddress(queryLendToken)];
          if (strategySupport) {
            // @ts-ignore
            return true;
          }
        }
        return false;
      });
      if (foundStrategy && foundStrategy[0])
        // @ts-ignore
        setSelectedStrategy(strategies[foundStrategy[0]]);
    }
    if (queryFeeType) {
      if (queryFeeType === "1") {
        setRateType("Fixed");
      } else if (queryFeeType === "2") {
        setRateType("Decay");
      } else if (queryFeeType === "3") {
        setRateType("Auction");
      }
    }
    // default the new pool expiry to 30 days
    setSelectedExpiry(addDays(new Date(), 30));
    setQueryParamsLoaded(true);
  }

  // approve the factory to deposit funds into pool on deployment
  const approveFactory = async () => {
    if (!supportedNetworks.includes(chainId) || !provider) return;
    // const approvalAmount = ethers.constants.MaxInt256;
    try {
      setPending(true);
      enqueueSnackbar("Please confirm the approval transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      const tokenContract = new ethers.Contract(
        selectedLend.address,
        ERC20ABI,
        provider.getSigner()
      );

      // @ts-ignore
      const factoryAddress = contractAddresses.POOL_FACTORY.address[chainId];
      let tx = await tokenContract.approve(
        factoryAddress,
        ethers.utils.parseUnits(Number(initialDeposit).toFixed(selectedLend.decimals), selectedLend.decimals),
        {
          ...getGasLimit(chainId, METHOD_TYPE.APPROVE)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Approval successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
      setLendAllowance(ethers.utils.parseUnits(initialDeposit.toString()));
    } catch (e) {
      enqueueSnackbar("Approval transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
  }

  // instantly set the pool as a rollover pool if originPool is passed in as a query param 
  const setRollover = async (originPoolAddress: string, newPoolAddress: string) => {
    if (!provider) return;
    setPending(true);
    try {
      enqueueSnackbar(`Please confirm the rollover pool creation transaction in your wallet`, {
        persist: false,
      });
      const poolContract = new ethers.Contract(
        originPoolAddress,
        LendingPoolABI,
        provider.getSigner()
      );
      // enable the new rollover pool or disable the currently selected pool
      const tx = await poolContract.setRolloverPool(
        newPoolAddress,
        true,
        {
          ...getGasLimit(chainId, METHOD_TYPE.SET_ROLLOVER)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Set rollover transaction successful ** ${getTransactionUrl(tx.transactionHash, chainId)}`, {
        persist: false,
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Set rollover transaction failed`, {
        persist: false,
      });
    }
    setPending(false);
  }


  // deploy new pool
  const deploy = async () => {
    if (!supportedNetworks.includes(chainId as number) || !provider) return;
    setPending(true);
    console.log("??")
    const poolFactoryContract = getPoolFactoryContract(
      provider.getSigner() as any,
      chainId as number
    );
    // const feeRate = Number(selectedAPR) * 10000;
    // const timestamp = new Date(selectedExpiry.replace('-','/')) / 1000
    const timestamp = Math.trunc(new Date(selectedExpiry).getTime() / 1000);
    console.log(poolFactoryContract);
    console.log(timestamp);
    // call deploy function
    try {
      enqueueSnackbar("Please confirm the deployment transaction in your wallet", {
        persist: false,
      });

      // if the rateType isn't auction then start date is now, and end date is expiry
      const auctionStartDate = rateType !== "Auction" 
        ? new Date().getTime() 
        : selectedAuctionDates[0].getTime();
      const auctionEndDate = rateType !== "Auction" 
        ? selectedExpiry.getTime() 
        : selectedAuctionDates[1].getTime();

      // get encoded bytes for setting up pool fees parameter
      const feeRatesAndType = encodeFeeRates(
        rateType === "Fixed" ? 1 : 2,
        selectedAPR[0] * 10000,
        selectedAPR[1] * 10000,
        auctionStartDate,
        auctionEndDate
      );

      // default to no strategy
      let strategyKey = "0x0000000000000000000000000000000000000000000000000000000000000000"
      if (selectedStrategy.name !== "No Strategy") {
        const strategyData = selectedStrategy
        strategyKey = strategyData.supportedTokens[selectedLend.address].key;
      }

      // sanitize initial deposit
      const _initialDeposit = initialDeposit 
        ? ethers.utils.parseUnits(initialDeposit.toString(), selectedLend.decimals).toString() 
        : "0"

      // setup maxLTV
      let maxLTV = autoPause ? 1000000 : 281474976710655;

      const deploymentParameters = {
        mintRatio: ethers.utils.parseUnits(selectedMintRatio.toString(), "ether").toString(),
        colToken: selectedCollateral.address,
        lendToken: selectedLend.address,
        feeRatesAndType: feeRatesAndType,
        poolType: BigNumber.from("0").toString(),
        strategy: strategyKey,
        borrowers: privateBorrowers,
        initialDeposit: _initialDeposit,
        expiry: timestamp,
        maxLTV: maxLTV,
        pauseTime: timestamp
      }
      console.log(deploymentParameters)
      // await approveFactory()
      const tx = await poolFactoryContract.deployPool(
        deploymentParameters,
        "0x",
      );
      let receipt = await tx.wait();
      const poolAddress = receipt.logs[0].address;
      enqueueSnackbar(`Pool creation transaction successful ** ${getTransactionUrl(receipt.transactionHash, chainId)}`, {
        persist: false,
      });
      // check if this pool creation is for a rollover pool
      const urlParams = new URLSearchParams(window.location.search);
      const originPoolQuery = urlParams.get("originPool");
      if (originPoolQuery !== null) {
        await setRollover(originPoolQuery, poolAddress);
      }
      setDeployedPoolAddress(poolAddress);
      setShowNewPool(true);
      setPending(false);
    } catch (e) {
      console.log(e);
      setPending(false);
      enqueueSnackbar(`Pool creation transaction failed`, {
        persist: false,
      });
    }
  };

  const renderPageStatus = () => {
    // setup marks for slider
    const marks = pages.map((name: string, index: number) => {
      return {
        value: index,
        label: name
      }
    });
    // listen for slider changes and update page
    const stepChange = (e:any, val:any) => {
      if (e.isTrusted && val < currentPage)
        setCurrentPage(val);
    }
    return(
      <div className="create-pool-page-status-wrapper">
        <Slider
          defaultValue={currentPage}
          value={currentPage}
          onChangeCommitted={stepChange}
          step={null}
          marks={marks}
          min={0}
          max={2}
        />
      </div>
    )
  }

  const renderRolloverNotice = () => {
    // check if this pool creation is for a rollover pool
    const urlParams = new URLSearchParams(window.location.search);
    const originPoolQuery = urlParams.get("originPool");
    if (originPoolQuery !== null)
      return (
        <div className="request-notice fade-in">
          <Alert severity="info">
            <div className="alert-header">You are creating a rollover pool</div>
            <Link to="/create-pool">
              After deploying this pool you will be asked to sign an additional
              transaction to set your rollover pool. This will allow your
              borrowers to rollover into the newly created pool. Click{" "}
              <u>here</u> to go back to normal mode
            </Link>
          </Alert>
        </div>
      );
    else return null;
  }

  const renderRequestNotice = () => {
    if (getCurrentPage() === "request-pool")
      return (
        <div className="request-notice fade-in">
          <Alert severity="info">
            <div className="alert-header">You are in request pool mode</div>
            <Link to="/create-pool">
              Request pool mode allows you to create parameters for a pool you
              would like a lender to create. This does not create a pool and does not cost any money. 
              Click <u>here</u> to go back to normal mode 
            </Link>
          </Alert>
        </div>
      );
    else return null;
  }

  // check if the user has submitted 3 requests
  const hasMaxRequests = () => {
    if (!account) return false;
    const filteredRequests = poolRequests.filter((request: any) => request.owner.toLowerCase() === account.toLowerCase());
    return filteredRequests.length >= 3;
  }

  // send a request to backend for a new pool
  const sendPoolRequest = async () => {

    if (!account || !provider) {
      // user can't make a request without a wallet
      enqueueSnackbar("Please connect your wallet to make a pool request");
      return;
    } else if (hasMaxRequests()) {
      // user can't make more than 3 requests
      enqueueSnackbar("Failed to submit request. You cannot have more than 3 requests at a time");
      return;
    }
    
    setPending(true);
    try {
      enqueueSnackbar("Please sign the message in your wallet to continue");

      // get recaptcha token
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      if (!token || token === "") {
        enqueueSnackbar("Failed to get recaptcha token");
        setPending(false);
        return;
      }

      // request user signature
      const signer = provider.getSigner();
      const message = "Please sign this message to request a pool";
      const signedMessage = await signer.signMessage(message);

      // setup url
      const url = useDevFunctions 
        ? new URL("http://localhost:5001/vendor-finace/us-central1/savePoolRequest")
        : new URL("https://us-central1-vendor-finace.cloudfunctions.net/savePoolRequest");

      // add params to url
      url.searchParams.append("colToken", selectedCollateral.address);
      url.searchParams.append("lendToken", selectedLend.address);
      url.searchParams.append("lendRatio", selectedMintRatio.toString());
      url.searchParams.append("colSymbol", selectedCollateral.symbol);
      url.searchParams.append("lendSymbol", selectedLend.symbol);
      url.searchParams.append("expiry", selectedExpiry.getTime().toString());
      url.searchParams.append("initialDeposit", initialDeposit.toString());
      url.searchParams.append("rateType", rateType);
      url.searchParams.append("startRate", selectedAPR[0].toString());
      url.searchParams.append("endRate", selectedAPR[1].toString());
      url.searchParams.append("startDate", selectedAuctionDates[0].toString());
      url.searchParams.append("endDate", selectedAuctionDates[1].toString());
      url.searchParams.append("owner", account);
      url.searchParams.append("chainId", chainId.toString());
      url.searchParams.append("signature", signedMessage);
      url.searchParams.append("reCaptchaResponse", token);

      // make request
      const request = await axios.get(url.href);
      setLatestPoolRequest(request.data);
      // update state
      setPoolRequests([...poolRequests, request.data]);
      enqueueSnackbar("Pool request successfully sent");
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to send pool request");
    }
    setPending(false);
  }

  const renderNextButton = () => {
    let rawInitialDeposit: any;
    // check the initial deposit amonut
    try {
      rawInitialDeposit = initialDeposit.toString().length === 0 
        ? BigNumber.from("0")
        : ethers.utils.parseUnits(Number(initialDeposit).toFixed(selectedLend.decimals), selectedLend.decimals);
    } catch (e) {
      console.log(e);
      rawInitialDeposit = BigNumber.from("0");
    }

    // button text logic
    let buttonText:any = "";
    if (stepButtonState === "") {
      buttonText = currentPage < pages.length - 1 
        ? (<span>{currentPage === 0 ? "Next" : "Review"} <ChevronRight/></span>)
        // request-pool vs create-pool page
        : getCurrentPage() === "request-pool" ? "Send Request" : "Deploy Pool";
    } else if (stepButtonState.indexOf("Approve") > -1) {
      buttonText = `Approve ${selectedLend.symbol}`;
    } else {
      buttonText = stepButtonState;
    }

    // update current page
    const updatePage = () => {
      // go to next page if not on last page
      if (currentPage === pages.length - 1) {
        // if on last page check lend token allowance and use approve or deploy
        if (lendAllowance.lt(rawInitialDeposit))
          approveFactory();
        else
          deploy();
      } else {
        // go to next page
        setCurrentPage(currentPage + 1);
      }
    }

    const renderNextStepButton = () => {
      // check if button should be disabled
      let disabled = 
        // disabled if state isn't empty
        (stepButtonState !== "" && currentPage !== pages.length - 1) ||
        // disabled if on last page and no account
        (!account && currentPage === pages.length - 1) ||
        // disabled if pending
        pending 

      // if on request-pool page, don't disable on last page
      if (
        getCurrentPage() === "request-pool" && 
        currentPage === pages.length - 1 && 
        !pending
      ) 
        disabled = false;

      // check if action should request pool or update page
      const action = getCurrentPage() === "request-pool" && currentPage === pages.length - 1
        ? sendPoolRequest 
        : updatePage;

      return (
        <ActionButton
          title={buttonText}
          disabled={disabled}
          action={action}
        />
      )
    }

    // render
    return (
      <div className="page-buttons-wrapper">
        {currentPage === 0 ? null : 
          <div className="back-button-wrapper">
            <ActionButton
              title="Back"
              disabled={false}
              action={() => setCurrentPage(currentPage - 1)}
              secondary={true}
            />
          </div>
        }
        {renderNextStepButton()}
      </div>
    )
  }

  return (
    <CreatePoolContext.Provider value={{ 
      selectedCollateral,
      setSelectedCollateral,
      selectedLend,
      setSelectedLend,
      selectedAPR,
      setSelectedAPR,
      selectedMintRatio,
      setSelectedMintRatio,
      selectedMintRatioButton,
      setSelectedMintRatioButton,
      selectedExpiry,
      setSelectedExpiry,
      stepButtonState,
      setStepButtonState,
      rateType,
      setRateType,
      initialDeposit,
      setInitialDeposit,
      lendAllowance,
      setLendAllowance,
      lendBalance,
      setLendBalance,
      selectedAuctionDates,
      setSelectedAuctionDates,
      selectedStrategy,
      setSelectedStrategy,
      privateBorrowers,
      setPrivateBorrowers,
      currentPage,
      setCurrentPage,
      queryParamsLoaded,
      protocolFee,
      setProtocolFee,
      autoPause,
      setAutoPause 
    }}>
      <div className="create-pool-wrapper fade-in">
        <ReCAPTCHA 
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          size="invisible"
        />
        <div className="create-pool-left page-left-section fade-in">
          <WrongNetwork/>
          {renderRequestNotice()}
          {renderRolloverNotice()}
          {screenWidth > tabletBreakpoint &&
            <GraphTabs/>
          }
          <PoolRequestsContainer 
            latestPoolRequest={latestPoolRequest}
            poolRequests={poolRequests}
            setPoolRequests={setPoolRequests}
          />
        </div>
        <div className="create-pool-right page-right-section fade-in">
          {screenWidth < tabletBreakpoint &&
            <GraphTabs/>
          }
          <div className="widget-wrapper view-section fade-in main-container-shadow">
            {renderPageStatus()}
            <Pages/>
            {renderNextButton()}
          </div>
          <AlertBox
            title="Pool Created!"
            message={baseURI + deployedPoolAddress}
            show={deployedPoolAddress !== ""}
            showNewPool={showNewPool}
            setShowNewPool={setShowNewPool}
            shrink={true}
          />
        </div>
      </div>
    </CreatePoolContext.Provider>
  );
};

export default CreatePool;